import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import InboxesAPI from '../../api/inboxes';
import WebChannel from '../../api/channel/webChannel';
import FBChannel from '../../api/channel/fbChannel';
import TwilioChannel from '../../api/channel/twilioChannel';
import { throwErrorMessage } from '../utils/api';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { ACCOUNT_EVENTS } from '../../helper/AnalyticsHelper/events';

const buildInboxData = inboxParams => {
  const formData = new FormData();
  const { channel = {}, ...inboxProperties } = inboxParams;
  Object.keys(inboxProperties).forEach(key => {
    formData.append(key, inboxProperties[key]);
  });
  const { selectedFeatureFlags, ...channelParams } = channel;
  // selectedFeatureFlags needs to be empty when creating a website channel
  if (selectedFeatureFlags) {
    if (selectedFeatureFlags.length) {
      selectedFeatureFlags.forEach(featureFlag => {
        formData.append(`channel[selected_feature_flags][]`, featureFlag);
      });
    } else {
      formData.append('channel[selected_feature_flags][]', '');
    }
  }
  Object.keys(channelParams).forEach(key => {
    formData.append(`channel[${key}]`, channel[key]);
  });
  return formData;
};

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isUpdatingIMAP: false,
    isUpdatingSMTP: false,
  },
};

export const getters = {
  getInboxes($state) {
    return $state.records;
  },
  getWhatsAppTemplates: $state => inboxId => {
    const user = window.localStorage.getItem('current_user');
    const ITXDSCampaignTemplatesUrl =
      JSON.parse(user).custom_attributes?.campaignUrl ??
      'https://campaign.testing.mutantds.com/api/v1/templates/get';
    console.log(`ITXDSCampaignTemplatesUrl: ${ITXDSCampaignTemplatesUrl}`);
    const apiKeyHeaderValue =
      JSON.parse(user).custom_attributes?.campaignApiKey ??
      'YWMyMDk0NGQtNzFmNC00OGQ1LTliNWYtMmRhZmI5YWIyOTcx';
    console.log(`apiKeyHeaderValue: ${apiKeyHeaderValue}`);
    let messagesTemplates = [];
    const mockMessagesTemplates = [
      {
        id: 4,
        name: 'turno_confirmacion',
        language: 'es',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'TEXT',
          body:
            'Hola {{1}}, su cita con {{2}} el {{3}} a las {{4}} ha sido confirmada. ¿Desea confirmar, reprogramar o cancelar su cita?',
        },
      },
      {
        id: 1,
        name: 'inicio_viaje',
        language: 'es',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'TEXT',
          body:
            '¡Hola {{1}}! En poco tiempo inicias un viaje. Necesitamos realizar los controles. Presiona el botón cuando estés listo.',
        },
      },
      {
        id: 2,
        name: 'confirmacion_compra',
        language: 'es_AR',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'DOCUMENT',
          body:
            'Le enviamos la confirmación de su compra {{1}} realizada el {{2}}',
        },
      },
      {
        id: 5,
        name: 'compra_seguimiento',
        language: 'es',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'TEXT',
          body:
            '¡Hola {{1}}! Su pedido {{2}} ha sido enviado y está en camino. El número de seguimiento es {{3}}. Gracias por comprar con nosotros.',
        },
      },
      {
        id: 7,
        name: 'compra_seguimiento',
        language: 'en',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'TEXT',
          body:
            'Hello {{1}}! Your order {{2}} has been shipped and is on its way. The tracking number is {{3}}. Thank you for shopping with us.',
        },
      },
      {
        id: 6,
        name: 'compra_seguimiento',
        language: 'pt_BR',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'IMAGE',
          body:
            'Olá {{1}}! Seu pedido {{2}} foi enviado e está a caminho. O número de rastreamento é {{3}}. Obrigado por comprar conosco.',
        },
      },
      {
        id: 3,
        name: 'atraso_pago',
        language: 'es',
        status: 'APPROVED',
        category: 'General',
        structure: {
          type: 'TEXT',
          body:
            '¡Hola {{1}}! Hemos notado un retraso en el pago de tu producto: {{2}}. ¿Podrías realizar el pago dentro de los próximos 30 días?',
        },
      },
    ];
    // return mockMessagesTemplates;
    return new Promise((resolve, _reject) => {
      InboxesAPI.getWhatsAppTemplates(
        ITXDSCampaignTemplatesUrl,
        apiKeyHeaderValue
      )
        .then(response => {
          messagesTemplates = response.data.templates;
          if (messagesTemplates instanceof Array) {
            const filteredTemplates = messagesTemplates.filter(
              template => template.structure.type === 'TEXT'
            );
            console.log(
              `filteredTemplates: ${JSON.stringify(filteredTemplates)}`
            );
            resolve(filteredTemplates);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          console.log(
            `Error while fetching templates from ITXDSCampaignTemplatesUrl: ${error}`
          );
          console.log(`Returning mock messages templates`);
          resolve(mockMessagesTemplates);
        });
    });
  },
  getNewConversationInboxes($state) {
    return $state.records.filter(inbox => {
      const { channel_type: channelType, phone_number: phoneNumber = '' } =
        inbox;

      const isEmailChannel = channelType === INBOX_TYPES.EMAIL;
      const isSmsChannel =
        channelType === INBOX_TYPES.TWILIO &&
        phoneNumber.startsWith('whatsapp');
      return isEmailChannel || isSmsChannel;
    });
  },
  getInbox: $state => inboxId => {
    const [inbox] = $state.records.filter(
      record => record.id === Number(inboxId)
    );
    return inbox || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getWebsiteInboxes($state) {
    return $state.records.filter(item => item.channel_type === INBOX_TYPES.WEB);
  },
  getTwilioInboxes($state) {
    return $state.records.filter(
      item => item.channel_type === INBOX_TYPES.TWILIO
    );
  },
  getSMSInboxes($state) {
    return $state.records.filter(
      item =>
        item.channel_type === INBOX_TYPES.SMS ||
        (item.channel_type === INBOX_TYPES.TWILIO && item.medium === 'sms')
    );
  },
  dialogFlowEnabledInboxes($state) {
    return $state.records.filter(
      item => item.channel_type !== INBOX_TYPES.EMAIL
    );
  },
};

const sendAnalyticsEvent = channelType => {
  AnalyticsHelper.track(ACCOUNT_EVENTS.ADDED_AN_INBOX, {
    channelType,
  });
};

export const actions = {
  revalidate: async ({ commit }, { newKey }) => {
    try {
      const isExistingKeyValid = await InboxesAPI.validateCacheKey(newKey);
      if (!isExistingKeyValid) {
        const response = await InboxesAPI.refetchAndCommit(newKey);
        commit(types.default.SET_INBOXES, response.data.payload);
      }
    } catch (error) {
      // Ignore error
    }
  },
  get: async ({ commit }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: true });
    try {
      const response = await InboxesAPI.get(true);
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
      commit(types.default.SET_INBOXES, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isFetching: false });
    }
  },
  createChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      const { channel = {} } = params;
      sendAnalyticsEvent(channel.type);
      return response.data;
    } catch (error) {
      const errorMessage = error?.response?.data?.message;
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(errorMessage);
    }
  },
  createWebsiteChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await WebChannel.create(buildInboxData(params));
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      sendAnalyticsEvent('website');
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      return throwErrorMessage(error);
    }
  },
  createTwilioChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await TwilioChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      sendAnalyticsEvent('twilio');
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  createFBChannel: async ({ commit }, params) => {
    try {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: true });
      const response = await FBChannel.create(params);
      commit(types.default.ADD_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      sendAnalyticsEvent('facebook');
      return response.data;
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isCreating: false });
      throw new Error(error);
    }
  },
  updateInbox: async ({ commit }, { id, formData = true, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isUpdating: true });
    try {
      const response = await InboxesAPI.update(
        id,
        formData ? buildInboxData(inboxParams) : inboxParams
      );
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdating: false });
      throwErrorMessage(error);
    }
  },
  updateInboxIMAP: async ({ commit }, { id, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: true });
    try {
      const response = await InboxesAPI.update(id, inboxParams);
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingIMAP: false });
      throwErrorMessage(error);
    }
  },
  updateInboxSMTP: async ({ commit }, { id, ...inboxParams }) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: true });
    try {
      const response = await InboxesAPI.update(id, inboxParams);
      commit(types.default.EDIT_INBOXES, response.data);
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isUpdatingSMTP: false });
      throwErrorMessage(error);
    }
  },
  delete: async ({ commit }, inboxId) => {
    commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: true });
    try {
      await InboxesAPI.delete(inboxId);
      commit(types.default.DELETE_INBOXES, inboxId);
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INBOXES_UI_FLAG, { isDeleting: false });
      throw new Error(error);
    }
  },
  reauthorizeFacebookPage: async ({ commit }, params) => {
    try {
      const response = await FBChannel.reauthorizeFacebookPage(params);
      commit(types.default.EDIT_INBOXES, response.data);
    } catch (error) {
      throw new Error(error.message);
    }
  },
  deleteInboxAvatar: async (_, inboxId) => {
    try {
      await InboxesAPI.deleteInboxAvatar(inboxId);
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_INBOXES_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_INBOXES]: MutationHelpers.set,
  [types.default.SET_INBOXES_ITEM]: MutationHelpers.setSingleRecord,
  [types.default.ADD_INBOXES]: MutationHelpers.create,
  [types.default.EDIT_INBOXES]: MutationHelpers.update,
  [types.default.DELETE_INBOXES]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
